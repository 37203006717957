<template>
    <div class="loginView">
        <div class="loginContainer col-6 row gx-0 justify-content-end">
            <div class="col-8 align-self-center">
                <h1 class="">{{ $t('login.passwordRecoveryHeadline') }}</h1>
                <b-form @submit="resetPassword">
                    <b-form-group>
                        <div class="">
                            <p>{{ $t('login.newPassword') }}</p>
                            <input type="password" v-model="password" class="form-control">
                        </div>
                        <div class="">
                            <p>{{ $t('login.repeatPassword') }}</p>
                            <input type="password" v-model="confirmPassword" class="form-control">
                        </div>

                        <div class="row justify-content-between">
                            <div class="passwordReset col align-self-center">
                                <router-link to="/login">
                                    {{ $t('login.backToLogin') }}
                                </router-link> 
                            </div>    
                            <div class="col-4">
                                <b-button :disabled="password.length < 3 || password !== confirmPassword" class="loginButton" type="submit" variant="primary">{{ $t('login.submitPasswordForgot') }}</b-button>
                            </div>    
                        </div>
                    </b-form-group>
                </b-form>   
                <div class="spacer l"/>
            </div>    
        </div> 
        <div class="logoContainer col-6">
            <img src="../assets/img/logo_pooling.svg">
        </div>
    </div>
</template>
<script>
    import DataService from '@/services/StrapiService'

    export default {
        name: 'ResetPassword',
        data() {
            return {
                password: '',
                confirmPassword: '',
                done: false,
                error: false,
                strapiUrl: DataService.getServerUrl()
            }
        },
        methods: {
            async resetPassword(e) {
                e.preventDefault()
                this.axios.post(this.strapiUrl+`/auth/reset-password`, {
                    code: this.$route.query.code,
                    password: this.password,
                    passwordConfirmation: this.confirmPassword
                })
                .then(() => {
                    this.done = true
                    this.$router.push("login")
                })
                .catch(e => {
                    e;
                    this.error = true
                })
            }
        },
    }
</script>
<style scoped>
</style>